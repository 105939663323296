<template>
  <div class="upsell-card" :style="backgroundImage">
    <div class="card-content">
      <div class="card-subtitle">
        <span v-for="(subtitle, index) in data.subtitle" :key="index">
          {{ subtitle }}
        </span>
      </div>
      <div class="card-title">{{ data.title }}</div>
      <template>
        <i18n
          v-if="data.sezzleModule && data.sezzlePrice"
          tag="div"
          path="my_codes_sezzle_description"
          class="card-description"
        >
          <span class="price-full">{{ data.price }}</span>
          <span class="price-mounth">{{
            `${data.sezzlePrice}/${$t("mounth")}`
          }}</span>
        </i18n>
        <div
          v-else-if="data.sezzleModule && !data.sezzlePrice"
          class="card-description"
        >
          <span class="price-full">
            {{ data.price }}
          </span>
        </div>
        <div v-else class="card-description"></div>
      </template>
    </div>
    <mem-button btn-type="secondary-dark" @click="openCard">
      {{ buttonText }}
    </mem-button>
  </div>
</template>
<script>
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: ["data"],
  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${
          this.data.accountBackgroundImage?.image?.file?.url || ""
        })`,
      };
    },
    buttonText() {
      return this.data.button?.text || "";
    },
    buttonUrl() {
      return this.data.button?.url || "";
    },
  },
  methods: {
    openCard() {
      let windowReference = window.open();
      windowReference.location = this.buttonUrl;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.upsell-card {
  min-height: 264px;
  padding: 32px 22px;
  background-size: cover;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: $mobile) {
    min-height: 200px;
  }
  .card-content {
    .card-subtitle {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2.4px;
      text-transform: uppercase;
    }
    .card-title {
      margin-top: 12px;
      margin-bottom: 12px;
      font-family: "Druk";
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%; /* 58.8px */
      letter-spacing: 0.25px;
      text-transform: uppercase;
    }
    .card-description {
      min-height: 40px;
      margin-bottom: 24px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      @media screen and (max-width: $mobile) {
        min-height: 34px;
      }
      .price-full {
        font-family: Druk;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: 40px; /* 100% */
        text-transform: uppercase;
      }
      .price-mounth {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
      }
    }
  }
}
</style>